'use strict';

const HEIGHT_IN = 70;
const HEIGHT_CM = 178;
const WEIGHT_KG = 71;
const WEIGHT_LB = 156;

import InputChangerController from './InputChangerController';
import UnitsConverter from './UnitsConverter';
import PerformanceController from './PerformanceController';

class PlanProfileFormController {
  constructor(container) {
    this.changeUnits = this.changeUnits.bind(this);
    this.container = container;
    this.selector = '[data-action=change-units]';
  }

  init() {
    this._initPerformanceController();
    return this.container.on('change', this.selector, this.changeUnits);
  }

  changeUnits(event) {
    if (event.originalEvent) {
      const value = this.container.find(this.selector).val();
      if (value === 'metric') {
        this._changeInputField('in', 'cm', 'height', HEIGHT_CM);
        this._changeInputField('lb', 'kg', 'weight', WEIGHT_KG);
        this._changeRaceFormToMetric();
        this._changeRacesToMetric();
        this.performanceController.convertToMetric();
        return this._changePerformanceToMetric();
      } else {
        this._changeInputField('cm', 'in', 'height', HEIGHT_IN);
        this._changeInputField('kg', 'lb', 'weight', WEIGHT_LB);
        this._changeRaceFormToEnglish();
        this._changeRacesToEnglish();
        this.performanceController.convertToEnglish();
        return this._changePerformanceToEnglish();
      }
    }
  }

  _changeInputField(from, to, attr, placeholder) {
    const newSelector = `plan_profile_personal_information_${attr}_${to}`;
    const currSelector = `plan_profile_personal_information_${attr}_${from}`;
    const div = this.container.find(`.${currSelector}`);
    this._changeInput(
      `plan_profile_personal_information_${attr}_${from}`,
      newSelector,
      `plan_profile[personal_information][${attr}_${to}]`,
      placeholder,
      `${from}_to_${to}`
    );
    div.find('label').attr('for', newSelector);
    return div.find('.tooltip-hint span').text(to);
  }

  _changeRaceFormToMetric() {
    const values = [
      'race_distance_mi,race_distance_km,race[distance_km],42.195 km,mi_to_km',
      'race_uphill_ft,race_uphill,race[uphill],390 m,ft_to_m',
      'race_downhill_ft,race_downhill,race[downhill],370 m,ft_to_m'
    ];
    return this._changeRaceForm(values);
  }

  _changeRaceFormToEnglish() {
    const values = [
      'race_distance_km,race_distance_mi,race[distance_mi],26.2 mi,km_to_mi',
      'race_uphill,race_uphill_ft,race[uphill_ft],1279 ft,m_to_ft',
      'race_downhill,race_downhill_ft,race[downhill_ft],1213 ft,m_to_ft'
    ];
    return this._changeRaceForm(values);
  }

  _changeRaceForm(values) {
    return _.each(values, el => this._changeInput.apply(this, el.split(',')));
  }

  _changeInput(currSelector, newSelector, name, placeholder, direction) {
    const div = this.container.find(`.${currSelector}`);
    div.removeClass(currSelector).addClass(newSelector);
    const input = div.find('input');
    return this._setInputValues(input, newSelector, name, placeholder, direction);
  }

  _setInputValues(input, newSelector, name, placeholder, direction) {
    const options = {
      name,
      placeholder,
      id: newSelector,
      direction
    };
    const service = new InputChangerController(input);
    return service.change(options);
  }

  _changeRacesToMetric() {
    const values = [
      { name: 'col3', direction: 'mi_to_km' },
      { name: 'col4', direction: 'ft_to_m' },
      { name: 'col5', direction: 'ft_to_m' }
    ];
    this._changeRaces(values);
    const titles = [
      I18n.t('simple_form.labels.race.uphill'),
      I18n.t('simple_form.labels.race.downhill')
    ];
    return this._changeRaceHead.apply(this, titles);
  }

  _changeRacesToEnglish() {
    const values = [
      { name: 'col3', direction: 'km_to_mi' },
      { name: 'col4', direction: 'm_to_ft' },
      { name: 'col5', direction: 'm_to_ft' }
    ];
    this._changeRaces(values);
    const titles = [
      I18n.t('simple_form.labels.race.uphill_ft'),
      I18n.t('simple_form.labels.race.downhill_ft')
    ];
    return this._changeRaceHead.apply(this, titles);
  }

  _changeRaces(values) {
    return _.each(this.container.find('.contest-form .race'), race =>
      _.each(values, function(obj) {
        const div = $(race).find(`.${obj.name}`);
        return div.text(new UnitsConverter(div.text()).convert(obj.direction));
      })
    );
  }

  _changeRaceHead(uphill, downhill) {
    this.container.find('.contest-form .head .col4').text(uphill);
    return this.container.find('.contest-form .head .col5').text(downhill);
  }

  _initPerformanceController() {
    const container = this.container.find('[data-controller=PerformanceController]');
    return this.performanceController = new PerformanceController(container);
  }

  _changePerformanceToEnglish() {
    const values = ['plan_profile_performance,plan_profile_performance_mi,plan_profile[performance_mi],7.5 mi/h,km_to_mi'];
    _.each(values, el => this._changeInput.apply(this, el.split(',')));
    $('.performance_hint span').text(I18n.t('plan_profile.running_experience.hint2_mi'));
    return $('.plan_profile_performance_mi label').text(I18n.t('activerecord.attributes.plan_profile.performance_mi'));
  }

  _changePerformanceToMetric() {
    const values = ['plan_profile_performance_mi,plan_profile_performance,plan_profile[performance],12.5 km/h,mi_to_km'];
    _.each(values, el => this._changeInput.apply(this, el.split(',')));
    $('.performance_hint span').text(I18n.t('plan_profile.running_experience.hint2'));
    return $('.plan_profile_performance label').text(I18n.t('activerecord.attributes.plan_profile.performance'));
  }
}

export default PlanProfileFormController;
