'use strict';

import PerformanceCalculator from './PerformanceCalculator';

class TestRaceController {
  constructor(form) {
    this.form = form;
  }

  init() {
    this.initTimePicker();
    this.bindTriggers();
  }

  initTimePicker() {
    $(this.form.elements['test_race[time]'])
      .timepicker(window.timepicker_params({ hourMax: 5 }));
  }

  bindTriggers() {
    $(this.form).on('ajax:success', this.formSubmitSuccess);
    $(this.form).on('ajax:error', this.formSubmitError);
    $(this.form.elements['test_race[units]']).on('change', this.changeUnits);
  }

  formSubmitSuccess = (ev, data, status, xhr) => {
    if (status === 'success') {
      this.calculateAnsDuration(data);
    }
  }

  formSubmitError = (event, response, message) => {
    if (response.status === 422) {
      const body = response.responseJSON;
      const errorBlock = $('.distance-calc-form .row.errors');
      errorBlock.css({'display': 'block'});
      errorBlock.empty();
      for (let key in body) {
        errorBlock.append(I18n.t(`plan_profile.popup.${key}`) + ' ' + body[key] + '<br/>');
      }
    }
  }

  changeUnits = (ev) => {
    const value = ev.target.value;
    this.changeInputDistance(value);
    this.changeInputElevation(value, 'uphill');
    this.changeInputElevation(value, 'downhill');
    this.changeLabelUnits(value);
  }

  changeInputDistance = (units) => {
    if (units === 'metric') {
      const node = this.form.elements['test_race[distance_mi]'];
      node.setAttribute('id', 'test_race_distance_km');
      node.setAttribute('name', 'test_race[distance_km]');
      node.value = new DistanceConverter(node.value).convert('mi_to_km');
    } else {
      const node = this.form.elements['test_race[distance_km]'];
      node.setAttribute('id', 'test_race_distance_mi');
      node.setAttribute('name', 'test_race[distance_mi]');
      node.value = new DistanceConverter(node.value).convert('km_to_mi');
    }
  }

  changeInputElevation = (units, field) => {
    if (units === 'metric') {
      const node = this.form.elements[`test_race[${field}_ft]`];
      node.setAttribute('id', `test_race_${field}`);
      node.setAttribute('name', `test_race[${field}]`);
      node.value = new DistanceConverter(node.value).convert('ft_to_m');
    } else {
      const node = this.form.elements[`test_race[${field}]`];
      node.setAttribute('id', `test_race_${field}_ft`);
      node.setAttribute('name', `test_race[${field}_ft]`);
      node.value = new DistanceConverter(node.value).convert('m_to_ft');
    }
  }

  changeLabelUnits = (units) => {
    const distanceLabel = this.form.getElementsByClassName('distance_unit')[0];
    const elevationLabels = this.form.getElementsByClassName('elevation_unit');
    if (units === 'metric') {
      distanceLabel.innerHTML = '(km)';
      for (let i = 0; i < elevationLabels.length; i++) {
        elevationLabels[i].innerHTML = '(m)';
      };
    } else {
      distanceLabel.innerHTML = '(mi)'
      for (let i = 0; i < elevationLabels.length; i++) {
        elevationLabels[i].innerHTML = '(ft)';
      };
    }
  }

  calculateAnsDuration = (data) => {
    const { distance, uphill, downhill, duration } = data;
    const calculator = new PerformanceCalculator(distance, duration, uphill, downhill);
    const ansDuration = calculator.calculate();
    this.setPerformanceValues(ansDuration);
  }

  setPerformanceValues(duration) {
    $('#plan_profile_performance_duration').val(this.formatTime(duration));
    $("#plan_profile_performance_distance option[value='10.0']")
      .prop('selected', true);
    $('#plan_profile_performance_distance').change();
    $('#distanceCalc').modal('hide');
  }

  formatTime(sec_numb) {
    let hours = Math.floor(sec_numb / 3600);
    let minutes = Math.floor((sec_numb - (hours * 3600)) / 60);
    let seconds = Math.floor(sec_numb - (hours * 3600) - (minutes * 60));
    if (hours < 10) { hours = `0${hours}`; }
    if (minutes < 10) { minutes = `0${minutes}`; }
    if (seconds < 10) { seconds = `0${seconds}`; }
    return hours + ':' + minutes + ':' + seconds;
  };
}

export default TestRaceController;
