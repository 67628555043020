'use strict';

class WelcomePopupController {
  constructor(container) {
    this.init = this.init.bind(this);
    this._success = this._success.bind(this);
    this.container = container;
    this.standardCampaignRadioBtn = this.container.find('input[name=standard_campaign_id]');
    this.specialCampaignRadioBtn = this.container.find('input[name=special_campaign_id]');
  }

  init() {
    this.standardCampaignRadioBtn.on('click', event => {
      return this._hideModal();
    });

    return this.specialCampaignRadioBtn.on('click', event => {
      return $.ajax({
        url: this.specialCampaignRadioBtn.data('url'),
        dataType: 'json',
        type: 'PUT',
        global: false,
        data: { user: { campaign_id: this.specialCampaignRadioBtn.val() } },
        success: this._success
      });
    });
  }

  _success(data, status, response) {
    return this._hideModal();
  }

  _hideModal() {
    return this.container.modal('hide');
  }
}

export default WelcomePopupController;
