'use strict';

import UnitsConverter from './UnitsConverter';

class InputChangerController {
  constructor(input) {
    this.input = input;
  }

  change(options) {
    this.input.attr({
      name: options.name,
      placeholder: options.placeholder,
      id: options.id
    });
    const currValue = this.input.val();
    if (currValue) {
      return this.input.val(new UnitsConverter(currValue).convert(options.direction));
    }
  }
}

export default InputChangerController;
