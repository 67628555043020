'use strict';

const KG_TO_LB = 2.2046226218487757;

class WeightConverter {
  constructor(value) {
    this.value = value;
  }

  convert(direction) {
    switch (direction) {
      case 'kg_to_lb': return Math.round(this.value * KG_TO_LB);
      case 'lb_to_kg': return Math.round(this.value / KG_TO_LB);
    }
  }
}

export default WeightConverter;
