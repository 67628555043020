'use strict';

class PerformanceController {
  constructor(container) {
    this.select = container.find('#plan_profile_performance_distance');
    this.inner = container.find('.customSelectInner');
  }

  convertToMetric() {
    const distances = {
      '5.0': '5.0 km',
      '10.0': '10.0 km',
      '21.1': '21.1 km',
      '42.2': '42.2 km'
    };
    return this._changePerformanceDistance(distances);
  }

  convertToEnglish() {
    const distances = {
      '5.0': '3.1 mi',
      '10.0': '6.2 mi',
      '21.1': '13.1 mi',
      '42.2': '26.2 mi'
    };
    return this._changePerformanceDistance(distances);
  }

  _changePerformanceDistance(distances) {
    const selected = this.select.find('option:selected').val();
    _.each(this.select.find('option'), option => $(option).text(distances[$(option).val()]));
    return this.inner.text(distances[selected]);
  }
}

export default PerformanceController;
