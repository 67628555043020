'use strict';

class UploadStatus {
  constructor(meta, form) {
    this._checkStatus = this._checkStatus.bind(this);
    this.checkStatusCallback = this.checkStatusCallback.bind(this);
    this.form = form;
    this.activity_id = meta.activity_id;
    this.filename = meta.filename;
    this.progress = meta.progress;
  }

  intervalCheckStatus() {
    return this.timerId = setTimeout(this._checkStatus, 1000);
  }

  _formatDate(date) {
    if (!date) { return; }
    date = jQuery.datepicker.parseDate('yy-mm-dd', date);
    return jQuery.datepicker.formatDate(this._dateFormatCurrentLocale(), date);
  }

  _dateFormatCurrentLocale() {
    return I18n.t('date.formats.default')
    .replace('%d', 'dd')
    .replace('%m', 'mm')
    .replace('%Y', 'yy');
  }

  _checkStatus() {
    return jQuery.ajax({
      type: 'GET',
      global: false,
      data: { activity_id: this.activity_id },
      url: '/users/uploaders/upload_status',
      success: this.checkStatusCallback
    });
  }

  checkStatusCallback(data, status, response) {
    if (response.status === 200) {
      if (data.activity.status === 'completed') {
        clearInterval(this.timerId);

        const errors = data.activity.upload_errors;
        if (errors) {
          jQuery.each(errors, (key, value) => {
            const error = I18n.t(`errors.training.${key}.${value}`);
            return this.form.container.append(`<p class='alert alert-danger'>${this.filename} ${error}</p>`);
          });
        } else {
          this.form.container.append(`<p class='alert alert-success'>${this.filename} ${I18n.t('training.file.uploaded_on')} <a href='/users/trainings?selected=${data.training.id}&start_date=${data.training.date_on}'>${this._formatDate(data.training.date_on)}</a>.</p>`);
          this._updateCalendar(data.training);
        }
        return this._updateProgressBar(data.training);
      } else {
        return setTimeout(this._checkStatus, 2000);
      }
    }
  }

  _updateProgressBar(training) {
    this.form.progressBar.add(this.progress);
    if (this.form.progressBar.completed()) {
      this.form.progressBar.reset();
      this.form.resetForm();
      if (this.form.uploadButton.length) { this.form.uploadButton.show(); }
      if (jQuery('.plugin_loader').length && training) {
        return window.startDate = training.date_on;
      }
    }
  }

  _updateCalendar(data) {
    const calendar = jQuery('#calendar');
    if (calendar.length) {
      const html = `<span class='calendar_training' data-id=${data.id}></span>`;
      const container = calendar.find(`.calendar_training[data-id=${data.id}]`);
      if (!container.length) { jQuery(`#${data.date_on}-${data.clock}`).append(html); }
      TrainingsCalendar.updateTraining(data, false, false);
      return window.rerenderCalendarTrainings();
    }
  }
};

export default UploadStatus;
