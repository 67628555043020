'use strict';

import WeightConverter from './WeightConverter';

class UnitsConverter {
  constructor(value) {
    this.value = value;
  }

  convert(direction) {
    switch (direction) {
      case 'kg_to_lb': return new WeightConverter(this.value).convert(direction);
      case 'lb_to_kg': return new WeightConverter(this.value).convert(direction);
      default: return new DistanceConverter(this.value).convert(direction);
    }
  }
}

export default UnitsConverter;
