'use strict';

class RaceFormController {
  constructor(form) {
    this.form = form;
    this.startDate = this.form.find('[name="race[start_date]"]').val();
    this.distanceKm = this.form.find('[name="race[distance_km]"]');
    this.distanceMi = this.form.find('[name="race[distance_mi]"]');
    this.kind = this.form.find('[name="race[kind]"]:checked').val();
    this.title = this.form.find('[name="race[title]"]').val();
  }

  dateFormatCurrentLocale() {
    return I18n.t('date.formats.default')
           .replace('%d', 'dd')
           .replace('%m', 'mm')
           .replace('%Y', 'yy')
  }

  parsedDate() {
    try {
      return $.datepicker.parseDate(this.dateFormatCurrentLocale(), this.startDate);
    } catch (error) {}
  }

  init() {
    let dist, dist_mi;
    // do any checks only if the race is in the future
    if (this.parsedDate() >= new Date()) {
      if (this.distanceKm.length > 0) {
        dist = parseInt(this.distanceKm.val().replace(/[^\d]/g, ''), 10);
      } else {
        dist_mi = parseInt(this.distanceMi.val().replace(/[^\d]/g, ''), 10);
      }
      const msg = I18n.t('race.errors.marathon_can_be_only_main_race', { race: this.title });
      let errBlock = this.form.next();
      if (!errBlock.is('.race_errors')) {
        errBlock = $('<div class="race_errors" style="display: none;"></div>');
        this.form.after(errBlock);
      }
      if ((this.kind !== 'main') && dist && (dist > 35000) && (errBlock.text() !== msg)) {
        errBlock.text(msg).show();
      } else if ((this.kind !== 'main') && dist_mi && (dist_mi > 20) && (errBlock.text() !== msg)) {
        errBlock.text(msg).show();
      } else {
        this.form.submit();
      }
    } else {
      this.form.submit();
    }
  }
}

export default RaceFormController;
