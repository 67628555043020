'use strict';

import UploadStatus from './UploadStatus';

class FileUploadForm {
  constructor(container, progressBar) {
    this.bindTriggers = this.bindTriggers.bind(this);
    this.triggerInputFile = this.triggerInputFile.bind(this);
    this.submitFiles = this.submitFiles.bind(this);
    this._sendFileSuccess = this._sendFileSuccess.bind(this);
    this._sendFileError = this._sendFileError.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.container = container;
    this.progressBar = progressBar;
    this.form = this.container.find('#file-upload');
    this.inputFile = this.container.find('input[type=file]');
    this.uploadButton = this.container.find('.btn');
  }

  bindTriggers() {
    this.uploadButton.on('click', this.triggerInputFile);
    return this.inputFile.on('change', this.submitFiles);
  }

  triggerInputFile(event) {
    event.preventDefault();
    return this.inputFile.click();
  }

  submitFiles(event) {
    const { files } = this.inputFile.get(0);
    const count = files.length;
    this.progressStep = 702 / (1.97 * count);
    if (count > 0) {
      this.uploadButton.hide();
      this.progressBar.start();
      return _.each(files, file => this.sendFile(file));
    }
  }

  sendFile(file) {
    const data = new FormData();
    data.append('file', file);
    return $.ajax({
      type: 'POST',
      url: '/users/uploaders',
      data,
      contentType: false,
      processData: false,
      global: false,
      success: this._sendFileSuccess,
      error: this._sendFileError
    });
  }

  _sendFileSuccess(data, status, response) {
    this.progressBar.add(this.progressStep);
    if (response.status === 200) {
      const attrs = {
        activity_id: data.activity_id,
        filename: data.filename,
        progress: this.progressStep
      };
      const service = new UploadStatus(attrs, this);
      return service.intervalCheckStatus();
    }
  }

  _sendFileError(response, status, message) {
    this.progressBar.reset();
    this.resetForm();
    if (this.uploadButton.length) { this.uploadButton.show(); }
    const error = I18n.t('file_upload.failed');
    return this.container.append(`<p class='alert alert-danger'>${error}</p>`);
  }

  resetForm() {
    return this.form[0].reset();
  }
};

export default FileUploadForm;
