'use strict';

const FACTOR_1 = 0.000000007;
const FACTOR_2 = 0.0000113;

class ElevationFactorCalculator {
  calculate(elevation) {
    return Math.pow((FACTOR_1 * elevation), 2) + (FACTOR_2 * elevation) + 1;
  }
}

export default ElevationFactorCalculator;
