export class PersonalInformationPushSettings {
  constructor(selector) {
    this.selector = selector;
  }

  attachListeners() {
    const $element = $(this.selector);
    if($element.length === 0) {
      return;
    }
    $element.timepicker(window.timepicker_params({
      timeFormat: 'HH:mm',
      stepMinute: 15,
      hourMin: 4,
      hourMax: 10,
      timeOnlyTitle: I18n.t('timepicker.sms_title'),
      timeText: I18n.t('timepicker.sms_time')
    }));
  }
}
