"use strict";

import "babel-polyfill";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ready from "./ready";
import RacePrognosisForm from "./RacePrognosisForm";
import FileUploadForm from "./FileUploadForm";
import UploadActivities from "./UploadActivities";
import App from "./App";
import RaceAutocompleteController from "./RaceAutocompleteController";
import PersonalInformationTelephone from "./PersonalInformationTelephone";
import RaceFormController from "./RaceFormController";
import TestRaceController from "./TestRaceController";
import { PersonalInformationPushSettings } from "./PersonalInformationPushSettings";

export default function main() {
  ready(() => {
    $(function () {
      const pushTimeInput = "#personal_information_setting_attributes_daily_training_notification_time";
      const pushSettings = new PersonalInformationPushSettings(pushTimeInput);
      pushSettings.attachListeners();

      let form;
      const body = $("body");

      I18n.locale = body.data("locale");
      I18n.defaultLocale = body.data("locale-default");
      I18n.fallbacks = true;


      $(".modal").on("shown.bs.modal", function (e) {
        $(this).find($(".modal select:not(.hasCustomSelect)")).customSelect();
      });

      $("input.i-checkbox").hide().after('<a class="trigger" href="#"></a>');
      $("input.i-checkbox:checked").siblings(".trigger").addClass("active");
      $(".trigger").click(function (e) {
        $(this).siblings("input.i-checkbox").click();
        $(this).toggleClass("active");
        return e.preventDefault();
      });

      $(".faq-list .question a").click(function (e) {
        e.preventDefault();
        return $(this).closest(".question").next(".answer").toggle();
      });

      $(".nav .coming_soon a").click((e) => e.preventDefault());

      $("#forum_link").on("click", function (event) {
        event.preventDefault();
        return $(this).closest("form").submit();
      });

      $("#webshop_link").on("click", function (event) {
        event.preventDefault();
        return $(this).closest("form").submit();
      });

      $("input, textarea").placeholder();

      const profile = $("#elevation_profile");
      if (profile.length) {
        const pp = new RaceElevationProfile(profile);
        pp.render();
      }

      const route = $("#map_canvas");
      if (route.length) {
        const rm = new RouteMap(route);
        rm.render();
      }

      //
      // deactivate_user.js.coffee
      //
      const deactivation_form = $("#deactivation_form");
      if (deactivation_form.length) {
        deactivation_form.on("submit", function (event) {
          if (!$("#confirm_deactivation:checked").length) {
            return event.preventDefault();
          }
        });

        $(".deactivate_account").on("click", function (event) {
          if ($("#confirm_deactivation").prop("checked") === false) {
            event.preventDefault();
            return $("#confim-deactivation-not-accepted.hidden").removeClass("hidden");
          }
        });

        $("#confirm_deactivation").on("change", function (event) {
          event.preventDefault();
          const deactivate_button = $("#deactivate_account");
          if ($("#confirm_deactivation").prop("checked") === true) {
            return deactivate_button.prop("disabled", false);
          } else {
            return deactivate_button.prop("disabled", true);
          }
        });
      }

      //
      // login_popup.js.coffee
      //
      if ($("#loginPopup").length) {
        $("#loginPopup").modal("show");
      }

      //
      // login.js.coffee
      //
      $("header .login-button.login").on("click", function (event) {
        $(this).toggleClass("active");
        $(".login-form").toggle();
        return false;
      });

      $(".datepicker a").on("click", function () {
        $(this).toggleClass("active");
      });

      $(document).on("click", function () {
        $(".login-form").hide();
        return $("header .login-button.login").removeClass("active");
      });

      $(".login-form").on("click", (event) => event.stopPropagation());

      // Header: On scroll make sticky

      $(window).on("scroll", function () {
        var fromTop = $(window).scrollTop();
        $("body.lp header").toggleClass("sticky", fromTop > 200);
      });

      //
      // video.js.coffee
      //
      $('.video-trainings .item a, .sidebar_videos_item a, #training_description a[href*="youtube"]').YouTubeModal({
        autoplay: 1,
        hl: I18n.locale,
        cc_load_policy: 1,
      });
      $(".video_header_link").click(function (event) {
        event.preventDefault();
        const training_id = $(".calendar_training.active").data("id");
        let href = $(this).attr("href");
        if (training_id) {
          href += `?training_id=${training_id}`;
        }
        return (window.location.href = href);
      });

      //
      // browser-warning.js
      //
      $("div.browser-warning-close").on("click", function (e) {
        $("div.browser-warning").hide();
      });

      //
      // pages glossar hide options
      //
      const glossarHide = function () {
        $(".glossar").click(function (e) {
          e.stopPropagation();
          if (e.target.className !== "tab-nav") {
            if (e.target.className !== "tab-open") {
              $(".tab-open:checked").prop("checked", false);
            }
          }
        });
      };
      if ($(".glossar").length) {
        glossarHide();
      }

      //
      // import_ical_url.js.coffee
      //
      $("#import_morning_at, #import_evening_at").timepicker(
        window.timepicker_params({ timeFormat: "HH:mm", hour: 9, minute: 0, timeText: I18n.t("timepicker.sms_time") })
      );

      const validForm = () => validInput($("#import_morning_at")) && validInput($("#import_evening_at"));

      var validInput = function (input) {
        const timeRegex = new RegExp("^([0-9]{2}):([0-9]{2})$");
        let valid = true;
        if (timeRegex.test(input.val())) {
          input.css({ "border-color": "#cccccc" });
        } else {
          input.css({ "border-color": "#ff0000" });
          valid = false;
        }
        return valid;
      };

      $("#import_morning_at, #import_evening_at").on("change", function () {
        if (validForm()) {
          setIcalUrl();
        }
      });

      var setIcalUrl = function () {
        $("#import_ical_url").val(
          $("#import_ical_url").val().split("?")[0] +
            "?morning_at=" +
            $("#import_morning_at").val() +
            "&evening_at=" +
            $("#import_evening_at").val()
        );
      };

      if ($("#import_ical_url").val()) {
        setIcalUrl();
      }

      // $(document).on('click', '#calendarExportBtn', function(event) {
      //   if (validForm()) {
      //     return $('#calendarExportPopup').modal('hide');
      //   } else {
      //     return event.preventDefault();
      //   }
      // });

      //
      // search.js.coffee
      //
      if ($("#search-results").length) {
        const word = $(".faq-list").data("query");
        $(".faq-list").highlight(word);
        $(".faq-list .question:first").closest(".question").next(".answer").toggle();
      }

      //
      // gps-upload.js.coffee
      //
      const attachListeners = function () {
        $("#gps-selector .item").click(function () {
          const type = $(this).attr("data-type");
          const frames = $("#gps-content .item");
          const el = $("#gps-content").find(`#gps-${type}`);
          const selectors = $("#gps-selector .item");
          selectors.each((i, e) => $(e).removeClass("selected"));

          $(this).addClass("selected");
          frames.each((i, elem) => $(elem).addClass("hidden"));

          el.removeClass("hidden");
          $("#gps-garmin > div").show();
          $("#gps-suunto > div").show();
          $("#garmin-iframe-placeholder").html("").hide();
          return $("#suunto-iframe-placeholder").html("").hide();
        });
      };

      const clearFiles = () => $("input[type=file]").val(null);

      const garminManualContainer = $("#garmin-manual");
      if (garminManualContainer.length) {
        const progressBar = new ProgressBar(garminManualContainer.find("div[role='progressbar']"));
        const fileUploadForm = new FileUploadForm(garminManualContainer, progressBar);
        fileUploadForm.bindTriggers();
      }

      attachListeners();
      clearFiles();

      let conn = $("#gps-content").find(".active:first").parent().parent().attr("id");
      if (conn) {
        conn = conn.split("-")[1];
        $("#gps-selector").find(`*[data-type=${conn}]`).trigger("click");
      } else {
        $("*[data-type=garmin]").addClass("selected");
      }

      $("#gpxUploadPopup").on("hidden.bs.modal", function (event, modal) {
        if ($("iframe#plugin-frame").length > 0) {
          const { startDate } = $("iframe#plugin-frame").get(0).contentWindow;
          if (startDate) {
            const u = new Url();
            u.query.start_date = startDate;
            window.location.href = u.toString();
          }
        }
        $("#gps-garmin > div").show();
        $("#gps-suunto > div").show();
        $("#garmin-iframe-placeholder").html("").hide();
        $("#suunto-iframe-placeholder").html("").hide();
        return $("#gpxUploadPopup .alert-success, #gpxUploadPopup .alert-danger").remove();
      });

      $(".header_user-area_link").click(function () {
        $(".notifications-button").removeClass("active");
        $(".notifications-dropdown").hide();
        $(this).toggleClass("active");
        $(".header_user-area_dropdown").toggle();
        return false;
      });

      $(document).click(function () {
        $(".header_user-area_link").removeClass("active");
        $(".header_user-area_dropdown").hide();
        $(".notifications-button").removeClass("active");
        return $(".notifications-dropdown").hide();
      });

      $(".header_user-area_dropdown").click(() => event.stopPropagation());

      const uploadActivitiesContainer = $(".app .header");
      if (uploadActivitiesContainer.length) {
        const uploadActivities = new UploadActivities(uploadActivitiesContainer);
        uploadActivities.bindTriggers();
        uploadActivities.updateCounter();
      }

      //
      // heat_map.js.coffee
      //
      let map;
      const initMap = function (latlngs) {
        // set up the map
        map = new L.Map("map");

        // create the tile layer with correct attribution
        const osmUrl = "https://api.maptiler.com/maps/ch-swisstopo-lbm-dark/{z}/{x}/{y}.png?key=Lj9g6VGZyZVitU4i13vX";
        const osmAttrib = 'Map data © <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
        const osm = new L.TileLayer(osmUrl, { minZoom: 4, maxZoom: 16, attribution: osmAttrib });

        if (latlngs[0]) {
          // Show all points from the Heatmap
          map.fitBounds(latlngs);
        } else {
          const latlng = $("#heat_map").data("latlng") || [46.80121, 8.226692];
          map.setView(new L.LatLng(latlng[0], latlng[1]), 9);
        }

        map.addLayer(osm);

        return L.heatLayer(latlngs, { radius: Math.max(4, 16 - map.getZoom()) }).addTo(map);
      };

      if ($("#map").length) {
        let url = `/users/heat_map/${$("#heat_map").data("heatmap-id")}/data`;
        initMap([]);
        $.ajax({
          url: url,
          type: "GET",
          dataType: "json",
          success: function (latlngs) {
            map.off();
            map.remove();
            initMap(latlngs);
          },
        });
      }

      //
      // languages.js.coffee
      //
      // animates alert in startpage
      $(".closebtn").click(function () {
        $(this).parent().parent().slideUp();
        sessionStorage.setItem("closeBanner", true);
        return $(this).css("transform", "rotate(-90deg)");
      });

      // mobile menu toggle
      $(".nav-icon").click(function () {
        $(this).toggleClass("open");
        return $(".mobile_menu_container").slideToggle("slow");
      });

      // language menu toggle
      $(".dropdown_menu_toggle").click(function () {
        $(".locales").slideToggle();
        $(this).toggleClass("active_arrow");
        return event.preventDefault();
      });

      $(".collapse").collapse();

      // animation for sections in startpage
      $(".js-wp-1").css("opacity", "0");
      $(".js-wp-1").waypoint(() => $(".js-wp-1").addClass("animated fadeInUp"), { offset: "70%" });
      $(".js-wp-2").css("opacity", "0");
      $(".js-wp-2").waypoint(() => $(".js-wp-2").addClass("animated fadeInRight"), { offset: "70%" });
      $(".js-wp-3").css("opacity", "0");
      $(".js-wp-3").waypoint(() => $(".js-wp-3").addClass("animated fadeInUp"), { offset: "70%" });
      $(".js-wp-4").css("opacity", "0");
      $(".js-wp-4").waypoint(() => $(".js-wp-4").addClass("animated fadeInUp"), { offset: "70%" });
      $(".js-wp-5").css("opacity", "0");
      $(".js-wp-5").waypoint(() => $(".js-wp-5").addClass("animated fadeInUp"), { offset: "70%" });
      $(".js-wp-6").css("opacity", "0");
      $(".js-wp-6").waypoint(() => $(".js-wp-6").addClass("animated fadeInUp"), { offset: "20%" });

      // scroll to routes inside Social events

      var index = 0,
        $routes = $(".route_info"),
        $anchor = $(".route_info_trigger");

      $anchor.each(function (index) {
        $anchor.eq(index).on("click", function (e) {
          return $("html, body").animate({ scrollTop: $($routes.eq(index)).offset().top }, 1000);
        });
      });

      $(".go_back").on("click", function (e) {
        if (document.referrer.includes("users/trainings")) {
          location.assign(window.location.href.replace(/events\/.*/, "agenda/"));
        } else {
          if(document.referrer.includes("runningcoach.me")){
            window.history.back();
          } else {
            window.location = 'https://runningcoach.me/events'
          }
        }
      });

      $(document).on("click", ".plan-icon.mountain-icon, .mountain-tooltip", function (e) {
        var title = `<p class="mountain-tooltip">${e.target.title}</p>`;
        var container = $(this).parent().parent();
        var is_present = $(".mountain-tooltip").is(":visible");
        if (!is_present) {
          $(title).insertAfter(container);
        } else {
          $(".mountain-tooltip").remove();
        }
      });

      //fullscreen stuff

      function fullscreen() {
        // check if fullscreen mode is available
        if (
          document.fullscreenEnabled ||
          document.webkitFullscreenEnabled ||
          document.mozFullScreenEnabled ||
          document.msFullscreenEnabled
        ) {
          // which element will be fullscreen
          var iframe = document.querySelector("#content");
          // Do fullscreen
          if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.webkitRequestFullscreen) {
            iframe.webkitRequestFullscreen();
          } else if (iframe.mozRequestFullScreen) {
            iframe.mozRequestFullScreen();
          } else if (iframe.msRequestFullscreen) {
            iframe.msRequestFullscreen();
          }
        } else {
          document.querySelector(".error").innerHTML = "Your browser is not supported";
        }
      }

      $("#trainingInfo").on("load", function () {
        iframeLoading();
      });

      // detect enter or exit fullscreen mode
      function iframeLoading() {
        const elem = document.querySelector("#trainingInfo");
        elem.contentDocument.addEventListener("webkitfullscreenchange", fullscreenChange);
        elem.contentDocument.addEventListener("mozfullscreenchange", fullscreenChange);
        elem.contentDocument.addEventListener("fullscreenchange", fullscreenChange);
        elem.contentDocument.addEventListener("MSFullscreenChange", fullscreenChange);
      }

      function fullscreenChange() {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        console.log("le change", fullscreenElement);
        if (fullscreenElement === null) {
          console.log("exit fullscreen");
          document.querySelector("#trainingInfo").contentDocument.querySelector("#map-canvas").classList.remove("fullscreen");
          document.querySelector("#trainingInfo").contentDocument.querySelector(".link_holder").style.display = "flex";
        }
      }

      //
      // Accepts Cookies
      //
      $(".cookies_accepted").on("click", function () {
        acceptCookie();
      });

      var cookie = getCookie("rc_accepted_cookies");
      if (cookie == null) {
        $("#cookies_container").removeClass("hidden");
        var visible_container = $("#cookies_container").is(":visible");
        if (visible_container) {
          $("header").on("click", function () {
            acceptCookie("user-action");
          });
        }
      }

      function acceptCookie(accept = null) {
        setCookie("rc_accepted_cookies", true, 365);
        if (accept != "user-action") {
          $("#cookies_container").addClass("hidden");
        }
      }

      function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      }

      function setCookie(name, value, days) {
        var expires = "";
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }

      // make iframe Fullscreen
      $(".go_fullscreen").on("click", function (e) {
        console.log("go fullscreen");
        fullscreen();
        $("#map-canvas").addClass("fullscreen");
        document.querySelector(".link_holder").style.display = "none";
      });

      // tabs in gold page
      $(".tab_links a").on("click", function (e) {
        const currentAttrValue = $(this).attr("href");
        $(currentAttrValue).show().siblings().hide();
        $(this).addClass("active").siblings().removeClass("active");
        return e.preventDefault();
      });

      $(".languages .lang-active").on("click", (event) => event.stopPropagation());

      $(".closebtn").on("click", (event) => event.stopPropagation());

      $(".features_wrap a").on("click", function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          event.preventDefault();
          const { hash } = this;
          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          return $("html, body").animate(
            { scrollTop: $(hash).offset().top },
            800,
            () =>
              // Add hash (#) to URL when done scrolling (default click behavior)
              (window.location.hash = hash)
          );
        }
      });

      // keyboard button in glossar
      $("#top.keyboard .tab_keyboard").on("click", function () {
        $("#top.keyboard .tab").toggleClass("active");
      });
      // on scroll show keyboard & hide keyboard
      $(window).on("scroll", function () {
        var fromTop = $(window).scrollTop();
        $("#top.keyboard .tab_keyboard").toggleClass("sticky", fromTop > 200);
        $("#top.keyboard .tab").removeClass("active");
      });

      $(".top-slider").flexslider({
        animation: "fade",
        controlsContainer: ".top-slider .slides-controls-wrap",
        start(slider) {
          return countView(slider);
        },

        after(slider) {
          return countView(slider);
        },
      });

      $(".bottom-slider").flexslider({
        animation: "fade",
        controlsContainer: ".bottom-slider .slides-controls-wrap",
      });

      //
      // oauth_authorized_popup.js.coffee
      //
      if ($("#oauthAuthorizedPopup").length) {
        return $("#oauthAuthorizedPopup").modal("show");
      }

      const testRaceForm = document.getElementsByClassName("distance-calc-form")[0];
      if (testRaceForm) {
        const testRaceController = new TestRaceController(testRaceForm);
        testRaceController.init();
      }

      // telephone input
      const telephoneInputPlaceholder = $('[name="personal_information[telephone]"]')[0];
      const telephoneField = $("#personal_information_phone_country_code");
      const telephonePlaceholder = new PersonalInformationTelephone(telephoneInputPlaceholder, telephoneField);
      telephonePlaceholder.bind();
      telephonePlaceholder.avoidNonNumbers();

      //
      // personal_information card behavior
      //
      $(document).on("click", "#connections_row .item", function () {
        $('#connections_row .item [data-action*="disconnect"]').parent().parent().parent().removeClass("hidden");
      });


      //
      // print.js.coffee
      //
      if ($("body").hasClass("print_view")) {
        if ($("#print-trainings").length) {
          const training = TrainingsCalendar.getTrainingData(".calendar_training");
          TrainingsCalendar.drawGraph(training);
          $("input[type=radio]").attr("checked", false).attr("disabled", "true");
        }

        $("#print-trainings .empty").html("");

        window.setTimeout("window.print()", 500);
      }

      //
      // print_race_prognosis_success.js
      //
      function printMe() {
        window.print();
      }

      var printButton = document.getElementById("print-pdf");

      if (printButton != null) {
        printButton.addEventListener("click", printMe);
      }

      //
      // plans.js.coffee
      //
      let planId;
      const money = function (insurance) {
        const currency = $("#plan_insurance").data("currency");
        return `${insurance}.- ${currency}`;
      };

      const calculateInsurance = (planId) =>
        $.ajax({
          type: "GET",
          url: `/plans/${planId}/insurance`,
          success(data) {
            const insurance = data["insurance"];
            if (insurance) {
              if ($("#plan_insurance").hasClass("hidden")) {
                $("#plan_insurance").removeClass("hidden");
              }
              $(".timestop-price").text(money(insurance));
              $("#subscription_insurance").val(insurance);
            }
          },
        });
      const showQualitopNode = (input) => {
        let node = $("#qualitop_note");
        if (input.data("behavior") == "Bronze") {
          if (node.length) {
            node.removeClass("hidden");
          }
        } else {
          if (node.length && !node.hasClass("hidden")) {
            node.addClass("hidden");
          }
        }
      };

      const updateApplyVoucherForm = (plaId) => $("#apply_voucher input[name=plan_id]").val(planId);

      const newSubscription = $("#new_subscription");

      if (newSubscription.length && $("#apply_voucher").length) {
        planId = $(".rates input[type='radio']:checked").val();
        updateApplyVoucherForm(planId);
      }

      if (newSubscription.length && $(".rates input[type='radio']:checked").val()) {
        let input = $(".rates input[type='radio']:checked");
        planId = input.val();
        calculateInsurance(planId);
        showQualitopNode(input);
      }

      $(document).on("change", '.rates input[type="radio"]', function (event) {
        $(".main").find(".overlay-i").show();
        planId = $(this).val();
        updateApplyVoucherForm(planId);
        calculateInsurance(planId);
        showQualitopNode($(this));
      });

      //
      // welcome_popup.js.coffee
      //
      const welcomePopup = $("#welcomePopup");
      if ($("#welcomePopup").length) {
        welcomePopup.modal("show");
      }

      if (
        welcomePopup.data("trialdays") === 0 &&
        welcomePopup.data("subscription") === "TrialSubscription" &&
        welcomePopup.data("redirect-to-payment")
      ) {
        welcomePopup.on("hidden.bs.modal", (event) => (window.location = "/subscriptions/new"));
      }

      //
      // subscriptions.js.coffee
      //
      $(".payment-type .btn-pay").on("click", function (event) {
        if ($("#accepted:checked").length) {
          return $("#alert-not-accepted").addClass("hidden");
        } else {
          event.preventDefault();
          window.scrollTo(0, 0);
          return $("#alert-not-accepted.hidden").removeClass("hidden");
        }
      });

      const pay_with_check = $("#pay_with_check");
      if (pay_with_check.length) {
        pay_with_check.on("click", function () {
          if ($("#accepted:checked").length) {
            return $("#subscriptionsCheckPopup").modal("show");
          }
        });
      }

      const pay_with_invoice = $("#pay_with_invoice");
      if (pay_with_invoice.length) {
        $("#subscriptionsInvoicePopup form").on("submit", function (event) {
          const attrs = [];
          $.each($(this).find("input[data-required=true]"), function (i, el) {
            const elm = $(el);
            if (elm.val().length === 0) {
              elm.addClass("error");
              return attrs.push(elm.attr("name"));
            } else {
              return elm.removeClass("error");
            }
          });
          if (attrs.length) {
            return event.preventDefault();
          }
        });

        pay_with_invoice.on("click", function () {
          if ($("#accepted:checked").length) {
            return $("#subscriptionsInvoicePopup").modal("show");
          }
        });
      }

      $("#new_subscription").on("submit", function (event) {
        if (!$("#new_subscription input[type=radio]").is(":checked")) {
          event.preventDefault();
          window.scrollTo(0, 0);
          return $("#alert-not-chosen.hidden").removeClass("hidden");
        }
      });

      $(document).on("change", "#i_have_promocode", (event) => $(".voucher_code").toggleClass("hidden"));

      if ($("#i_have_promocode").prop("checked")) {
        $(".voucher_code").removeClass("hidden");
      }

      //
      // registration.js.coffee
      //
      const address_fields = function () {
        if ($("#user_personal_information_attributes_address_attributes_strict_validation_attribute").prop("checked")) {
          $("#user-address-fields").show();
          $("#user_personal_information_attributes_subscribe_on_magazine").prop("checked", true);
        }

        return $("#user_personal_information_attributes_address_attributes_strict_validation_attribute").on("change", function (event) {
          event.preventDefault();
          if ($(this).prop("checked")) {
            $("#user-address-fields").show();
            return $("#user_personal_information_attributes_subscribe_on_magazine").prop("checked", true);
          } else {
            $("#user-address-fields").hide();
            return $("#user_personal_information_attributes_subscribe_on_magazine").prop("checked", false);
          }
        });
      };

      const select_country = () => $("#user_personal_information_attributes_address_attributes_country_code").customSelect();

      const select_fitness_park = () => $("#user_personal_information_attributes_fitness_park_id").customSelect();

      select_country();
      select_fitness_park();
      address_fields();

      $("#promo-code-link").on("click", function (event) {
        event.preventDefault();
        $(this).remove();
        return $("#promo-code-field").show();
      });

      if ($("#user_voucher_code").val()) {
        $("#promo-code-link").remove();
        $("#promo-code-field").show();
      }

      //
      // quevita_rc_base.js.coffee
      //
      const app = new App($("body"));
      app.run();

      //
      // features_popup.js.coffee
      //
      const popup = $("#featuresPopup");
      if (popup.length) {
        popup.modal("show");
      }

      return $("#open_gpx_popup").on("click", function (event) {
        event.preventDefault();
        $("#featuresPopup").modal("hide");
        return $("#gpxUploadPopup").modal("show");
      });
    });
  });
}
