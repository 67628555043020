'use strict';

import ElevationFactorCalculator from './ElevationFactorCalculator';

class PerformanceCalculator {
  constructor(distance, duration, uphill, downhill) {
    this.distance = distance;
    this.duration = duration;
    this.uphill = uphill;
    this.downhill = downhill;
  }

  calculate() {
    return this.duration * Math.pow((10000.0 / this.distanceWithElevationFactor()), 1.07);
  }

  distanceWithElevationFactor() {
    return this.flatDistance() * new ElevationFactorCalculator().calculate(400);
  }

  flatDistance() {
    const uphillFactor = 5.5 + ((Math.log(Math.max(500, this.uphill) / 500) / Math.LN10) / (Math.log(1.6) / Math.LN10));
    return this.distance + (uphillFactor * this.uphill) - (2 * this.downhill);
  }
}

export default PerformanceCalculator;
