'use strict';

// telefon input
const PREFIX = {
  ['41']: '078 123 45 67',
  ['49']: '01512 3456789',
  ['43']: '0664 123456',
  ['61']: '0412 345 678',
  ['44']: '07400 123456',
  ['1']: '(201) 555-0123',
  ['46']: '070-123 45 67',
  ['47']: '406 12 345',
  ['358']: '041 2345678',
  ['45']: '20 12 34 56',
  ['34']: '612 34 56 78',
  ['54']: '011 15-2345-6789',
  ['57']: '321 1234567',
  ['56']: '9 6123 4567',
  ['52']: '044 222 123 4567',
  ['51']: '912 345 678',
  ['58']: '0412-1234567',
  ['32']: '0470 12 34 56',
  ['385']: '092 123 4567',
  ['420']: '601 123 456',
  ['372']: '5123 4567',
  ['33']: '06 12 34 56 78',
  ['30']: '691 234 5678',
  ['36']: '(20) 123 4567',
  ['353']: '085 012 3456',
  ['39']: '312 345 6789',
  ['371']: '21 234 567',
  ['31']: '06 12345678',
  ['48']: '512 345 678',
  ['351']: '912 345 678',
  ['7']: '8 (912) 345-67-89',
  ['421']: '0912 123 456',
  ['386']: '031 234 567',
  ['90']: '0501 234 56 78',
  ['58']: '0412-1234567',
  ['591']: '71234567',
  ['55']: '(11) 96123-4567',
  ['506']: '8312 3456',
  ['593']: '099 123 4567',
  ['502']: '5123 4567',
  ['504']: '9123-4567',
  ['972']: '050-123-4567',
  ['423']: '660 234 567',
  ['507']: '6123-4567',
  ['595']: '0961 456789',
  ['598']: '094 231 234',
  'undefined': '078 123 45 67'
}

class PersonalInformationController {
  constructor(container, field) {
    this.inputPlaceholder = container;
    this.field = field;
  }

  bind() {
    this.field.change(() => {
      const selectedCountry = $('#personal_information_phone_country_code option:selected')[0].value;
      const numberAmount = document.getElementById('personal_information_telephone').placeholder.replace(/\D/g,'').length;
      const placeme = Object.keys(PREFIX).find(item => item === selectedCountry);
      this.inputPlaceholder.placeholder = PREFIX[placeme];
      if (placeme !== 'undefined') {
        $('#personal_information_telephone').attr('maxlength', numberAmount);
      }
    });
  }

  avoidNonNumbers(){
    const telephoneInput = document.querySelector("#personal_information_telephone");
    if (telephoneInput) {
      telephoneInput.addEventListener("keypress", function (e) {
        if (e.which < 48 || e.which > 57) {
          e.preventDefault();
        }
      });
    }
  }

}

export default PersonalInformationController;
