'use strict';

class UploadActivities {
  constructor(container) {
    this.updateCounter = this.updateCounter.bind(this);
    this._openNotifications = this._openNotifications.bind(this);
    this._fetchNotificationsCallback = this._fetchNotificationsCallback.bind(this);
    this._updateCounterCallback = this._updateCounterCallback.bind(this);
    this.container = container;
    this.notificationsLink = this.container.find('.notifications-button');
    this.notificationsDropdown = this.container.find('.notifications-dropdown');
  }

  bindTriggers() {
    return this.notificationsLink.on('click', this._openNotifications);
  }

  updateCounter(timestamp) {
    return $.ajax({
      url: '/users/upload_activities/count_unread',
      data: { opened_notifications_at: timestamp },
      type: 'GET',
      global: false,
      success: this._updateCounterCallback
    });
  }

  fetchNotifications() {
    return $.ajax({
      url: '/users/upload_activities',
      type: 'GET',
      global: false,
      success: this._fetchNotificationsCallback
    });
  }

  _openNotifications(event) {
    $('.header_user-area_link').removeClass('active');
    $('.header_user-area_dropdown').hide();
    this.notificationsDropdown.empty();
    this.fetchNotifications();
    this.notificationsLink.toggleClass('active');
    this.notificationsDropdown.toggle();
    this.updateCounter(Math.floor(new Date().getTime() / 1000));
    return false;
  }

  _fetchNotificationsCallback(data) {
    if (data.length > 0) {
      $.each(data, (key, value) => {
        const { date_on } = value;
        if (date_on) {
          const url = `/users/trainings?selected=${value.training_id}&start_date=${date_on}`;
          const date = I18n.l('date.formats.default', date_on);
          const message = I18n.t('notifications.upload_finished', {date});
          const line = `<li><a href=${url}>${message}</a></li>`;
          return this.notificationsDropdown.append(line);
        }
      });
    }

    if (!this.notificationsDropdown.children().length) {
      const line = `<li class='none'>${I18n.t('notifications.no_notifications')}</a></li>`;
      return this.notificationsDropdown.append(line);
    }
  }

  _updateCounterCallback(data) {
    const counter = this.notificationsLink.find('.counter');
    if (data > 0) {
      if (counter.length) {
        return counter.html(data);
      } else {
        return this.notificationsLink.prepend(`<span class='counter'>${data}</span>`);
      }
    } else {
      if (counter.length) { return counter.remove(); }
    }
  }
};

export default UploadActivities;
