'use strict';

import WelcomePopupController from './WelcomePopupController';
import PlanProfileFormController from './PlanProfileFormController';

class App {
  constructor(container) {
    this.container = container;
    this.components = [];
    this._initAlert();
    this._initPlanProfileController();
    this._initWelcomePopupController();
  }

  run() {
    return _.each(this.components, component => component.init());
  }

  _initAlert() {
    const container = this.container.find('[data-controller=AlertController]');
    if (container.length === 1) {
      const alert = new AlertController(container);
      return this.components.push(alert);
    }
  }

  _initPlanProfileController() {
    const selector = '[data-controller=PlanProfileFormController]';
    const container = this.container.find(selector);
    if (container.length === 1) {
      const planProfileForm = new PlanProfileFormController($(container));
      return this.components.push(planProfileForm);
    }
  }

  _initWelcomePopupController() {
    const selector = '#welcomePopup';
    const container = this.container.find(selector);
    if (container.length === 1) {
      const welcomePopup = new WelcomePopupController($(container));
      return this.components.push(welcomePopup);
    }
  }
}

export default App;
